<script>
import { mask } from "vue-the-mask"
import AuthService from "@/services/auth.service"
import WebStorage from "@/services/web-storage"
import {mdiArrowLeft, mdiEmail, mdiKey} from "@mdi/js"
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const emailVSMServiseRegex = /^[^\s@]+@vsmservice.ru$/
export default {
	name: "AuthView",
	directives: {
		mask
	},
	data: function() {
		return {
			isEmailValid: false,
			isCodeValid: false,
			gettingCode: false,
			signingIn: false,
			firstStep: true,
			code: "",
			email: "",
			saveEmail: true,
			icons: {
				email: mdiEmail,
				key: mdiKey,
				arrowLeft: mdiArrowLeft
			},
			codeLength: 4,
			codeRules: [
				value => !!value || this.$t("pages.auth.rules.code-required"),
				value => value.length === this.codeLength || this.$t("pages.auth.rules.code-length", {length: this.codeLength})
			],
			emailRules: [
				value => !!value || this.$t("pages.auth.rules.email-required"),
				value => emailRegex.test(value) || this.$t("pages.auth.rules.email-format"),
				value => emailVSMServiseRegex.test(value) || this.$t("pages.auth.rules.email-corp-format")
			],
		}
	},
	computed: {
		maskPattern() {
			return Array(this.codeLength).fill("#").join("")
		}
	},
	mounted() {
		if (WebStorage.getEmail() && WebStorage.getEmail().length > 0)
			this.email = WebStorage.getEmail()
	},
	methods: {
		onGetCode() {
			if (!this.isEmailValid) return
			this.gettingCode = true
			setTimeout(async () => {
				if (await AuthService.code(this.email)) {
					this.firstStep = false
					this.focus("code")
					if (this.saveEmail) {
						this.savePhone()
					} else {
						this.deletePhone()
					}
					await this.$store.dispatch("App/setSnackbar", false)
				} else {
					await this.$store.dispatch(
						"App/setSnackbar",
						{ message: this.$t("snackbar.message-auth-email"), type: "error" }
					)
				}
				this.gettingCode = false
			}, 0)
		},
		onSignIn() {
			if (!this.isCodeValid || !this.isEmailValid) return
			this.signingIn = true
			setTimeout(async () => {
				const res = await AuthService.login({
					email: this.email,
					code: this.code
				})
				if (res) {
					await this.$router.push("/")
					await this.$store.dispatch("App/setSnackbar", false)
				} else {
					await this.$store.dispatch(
						"App/setSnackbar",
						{ message: this.$t("snackbar.message-auth-code"), type: "error" }
					)
				}
				this.signingIn = false
			})
		},
		focus(ref) {
			setTimeout(() => {
				(this.$refs[ref]).focus()
			}, 0)
		},
		reset() {
			this.firstStep = true
		},
		savePhone() {
			WebStorage.setEmail(this.email)
		},
		deletePhone() {
			WebStorage.removeEmail()
		}
	}
}
</script>

<template>
	<v-container
		fluid
		fill-height
	>
		<v-row
			align="center"
			justify="center"
		>
			<v-col
				cols="12"
				sm="8"
				md="4"
				class="px-2 py-0 mb-5"
			>
				<v-img
					alt="VSM Service"
					class="mx-auto"
					contain
					max-width="150"
					src="@/assets/logo/logo_c.png"
				/>
				<v-card
					tile
					class="mx-auto elevation-0"
					:min-width="304"
					:max-width="400"
				>
					<v-toolbar
						flat
						class="my-0 py-0"
					>
						<v-btn
							v-if="!firstStep"
							small
							:disabled="signingIn"
							text
							class="my-0 py-0 font-weight-light"
							@click="reset"
						>
							<v-icon small>
								{{ icons.arrowLeft }}
							</v-icon>{{ $t("pages.auth.back-btn") }}
						</v-btn>
						<v-spacer />
					</v-toolbar>
					<v-card-text class="py-0 my-0">
						<v-form
							v-model="isEmailValid"
							@submit.prevent="onGetCode"
						>
							<v-tab-transition>
								<v-text-field
									v-show="firstStep"
									ref="email"
									v-model="email"
									filled
									:disabled="gettingCode"
									rounded
									autofocus
									class="font-weight-light"
									autocomplete="email"
									:label="$t('pages.auth.email-input-label')"
									:prepend-inner-icon="icons.email"
									:rules="emailRules"
								/>
							</v-tab-transition>
							<v-checkbox
								v-show="firstStep"
								v-model="saveEmail"
								:disabled="gettingCode"
								dense
								hide-details
								class="font-weight-light"
								:label="$t('pages.auth.save-email')"
							/>
						</v-form>
						<v-form
							v-model="isCodeValid"
							@submit.prevent="onSignIn"
						>
							<v-tab-transition>
								<v-text-field
									v-show="!firstStep"
									ref="code"
									v-model="code"
									v-mask="maskPattern"
									filled
									:disabled="signingIn"
									rounded
									:label="$t('pages.auth.code-input-label')"
									class="font-weight-light codeInput"
									:prepend-inner-icon="icons.key"
									:rules="codeRules"
								/>
							</v-tab-transition>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-row>
							<v-col cols="12">
								<v-btn
									v-show="firstStep"
									:loading="gettingCode"
									block
									large
									tile
									:disabled="!isEmailValid"
									color="primary"
									@click.stop="onGetCode"
								>
									{{ $t("pages.auth.get-code-btn") }}
								</v-btn>
								<p
									v-show="firstStep"
									class="mt-2 caption font-weight-light"
								>
									{{ $t("pages.auth.terms-text") }}
									<a
										href="#"
										target="_blank"
									>
										{{ $t("pages.auth.terms-link") }}
									</a>
								</p>
								<v-btn
									v-show="!firstStep"
									:loading="signingIn"
									block
									tile
									large
									:disabled="!isCodeValid || !isEmailValid"
									color="primary"
									@click.stop="onSignIn"
								>
									{{ $t("pages.auth.sign-in-btn") }}
								</v-btn>
							</v-col>
						</v-row>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>


<style>
.codeInput > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  -webkit-text-security: disc;
}
</style>